import { createMuiTheme } from '@material-ui/core/styles'

const white = '#fff'
const grey = '#323233'
const primaryMain = '#3643BA'
const primaryDark = '#0075af'
const secondaryMain = '#ffea28'
const secondaryDark = '#fdd935'

const imp = '!important'

const customSelect = {
  fontSize: `14px ${imp}`,
  borderRadius: `6px ${imp}`,
  border: `1px solid #EDEFF1 ${imp}`,
  padding: `7px 10px ${imp}`,
  background: `#EDEFF1 ${imp}`,
  '&:focus': {
    outline: `none ${imp}`,
  },
}

const theme = createMuiTheme({
  themeFont: {
    //WARNING FONT NAME WITH SPACE SHOULD HAVE A +. NEXT TRIGGER BAD URL FOR GOOGLE FONT
    main: 'Roboto+Condensed',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
  },
  typography: {
    fontFamily: "'Roboto Condensed', sans-serif",
    h1: {
      fontSize: 32,
      lineHeight: 1.15,
      fontWeight: 700,
      textTransform: 'uppercase',
      fontStyle: 'italic',
    },
    h2: {
      fontSize: 24,
      lineHeight: 1.15,
      fontWeight: 700,
      textTransform: 'uppercase',
      fontStyle: 'italic',
    },
    h3: {
      fontSize: 18,
      lineHeight: 1.4,
      fontWeight: 700,
      textTransform: 'uppercase',
      fontStyle: 'italic',
    },
    h4: {
      fontSize: 16,
      lineHeight: 1.55,
      fontWeight: 700,
    },
    h5: {
      fontSize: 14,
      lineHeight: 1.25,
      fontWeight: 700,
    },
    h6: {
      fontSize: 12,
      lineHeight: 1.25,
      fontWeight: 700,
    },
    body1: {
      fontSize: 15,
      lineHeight: 1.67,
      fontFamily: "'Roboto Condensed', sans-serif",
    },
    body2: {
      fontSize: 13,
      lineHeight: 1.67,
      fontFamily: "'Roboto Condensed', sans-serif",
    },
    caption: {
      fontSize: 12,
      lineHeight: 1.17,
      fontWeight: 400,
      fontFamily: "'Roboto Condensed', sans-serif",
    },
    button: {
      fontSize: 14,
      lineHeight: 1.28571429,
      fontWeight: 700,
      fontFamily: "'Roboto Condensed', sans-serif",
    },
  },
  palette: {
    common: {
      black: grey,
      white: white,
    },
    background: {
      default: white,
      paper: white,
    },
    primary: {
      main: primaryMain,
      dark: primaryDark,
    },
    secondary: {
      main: secondaryMain,
      dark: secondaryDark,
      contrastText: grey,
    },
    success: {
      main: '#47E169',
    },
    error: {
      main: '#FA3232',
    },
    warning: {
      main: '#FF8333',
    },
    divider: '#000',
    primaryTransparent: {
      main: 'rgba(0, 0, 0, .5)',
      light: 'rgba(0, 0, 0, .1)',
    },
    secondaryTransparent: {
      main: 'rgba(118, 118, 118, 1)',
      light: 'rgba(118, 118, 118, .1)',
    },
    ternary: {
      main: primaryMain,
      light: '#F1FAFF',
    },
    accent: {
      main: '#767677',
    },
  },
  sizes: {
    headerHeight: 60,
    headerHeightTransparent: 90,
    logoWidth: 110,
    logoHeight: 30,
    footerBG: 168,
    basketWidth: 340,
    basketWidthLG: 440,
  },
  extraColors: {
    grey: grey,
    greyLight: '#edeff1',
    greyMedium: '#575859',
    greyText: '#575859',
  },
  shadows: [
    'none',
    '0 0 0 1px #edeff1',
    '0 0 0 1px #edeff1',
    '0 0 0 1px #edeff1',
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
    '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  ],
  spacing: 8,
  shape: {
    borderRadius: 2,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '.react-datepicker__navigation::before, .react-datepicker__navigation::after':
          {
            backgroundColor: `${grey}!important`,
          },
        '.react-datepicker__day--in-range, .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected':
          {
            color: `${grey}!important`,
          },
        '.react-datepicker-popper>div>div>div+div': {
          backgroundColor: `${primaryMain}!important`,
          '&>div': {
            color: `${white}!important`,
          },
        },
        '.datePicker': {
          '&__background': {
            backgroundColor: `${primaryMain}!important`,
            '&>div': {
              color: `${white}!important`,
            },
          },
        },
        '.select': {
          ...customSelect,
        },
        '.label': {
          fontSize: '9px !important',
        },
        '.banner': {
          height: '50px !important',
        },
        '.account__hidden, .account-login__button, .account__switch, .login-form__button':
          {
            // display: 'none !important',
          },
        '.section-hiw ': {
          '@media (max-width: 768px)': {
            maxWidth: `80% ${imp}`,
            margin: `auto ${imp}`,
          },
          '& .MuiContainer-root': {
            paddingLeft: `0 ${imp}`,
            paddingRight: `0 ${imp}`,
            maxWidth: `1360px ${imp}`,
          },
          '& .MuiGrid-grid-md-4': {
            '@media (min-width: 768px)': {
              flexBasis: `${100 / 4}% ${imp}`,
              maxWidth: `${100 / 4}% ${imp}`,
            },
          },
          '&__title': {},
        },
        '.productCard': {
          '&__cta': {
            backgroundColor: `${primaryMain} !important`,
            '&:after': {
              backgroundColor: `${primaryDark} !important`,
            },
            '&:hover, &:focus': {
              color: `${white} !important`,
            },
          },
          '&__price': {
            color: `${grey} !important`,
            backgroundColor: secondaryMain,
            paddingLeft: 8,
            paddingRight: 8,
          },
        },
        '[data-testid=continue]:after': {
          display: 'none',
        },
        '.funnel': {
          '&__header': {
            textTransform: 'uppercase !important',
            fontStyle: 'italic',
            color: `${grey} !important`,
            backgroundColor: `${secondaryMain} !important`,
          },
          '&__nav': {
            textTransform: 'uppercase !important',
            '&--active': {
              color: `${primaryMain} !important`,
              borderColor: `${primaryMain} !important`,
            },
          },
        },
        '.deliveryDate': {
          color: `${primaryMain} !important`,
        },
        '.menu__item': {
          fontSize: `14px !important`,
        },
        '.menu-mobile__content': {
          backgroundColor: `${white} !important`,
        },
        '.basket__btn': {
          //background: `${secondaryMain} !important`,
          borderRadius: `0 !important`,
          border: `0 !important`,
          background: `${primaryMain} !important`,
          transform: 'skew(-10deg, 0deg) !important',
          height: `61px !important`,
          '&:before': {
            display: 'none',
          },
          '& .injected-svg': {
            fontSize: '2rem !important',
          },
          '&.MuiButton-root': {
            //background: `${secondaryMain} !important`,
            '& .MuiButton-label': {
              transform: 'skew(10deg, 0deg) !important',
              '&>div': {
                padding: '0 4px!important',
                border: '0 !important',
                color: `${grey} !important`,
                background: `${secondaryMain} !important`,
                bottom: '-10px !important',
                right: '-5px !important',
              },
            },
          },
        },
        '.basket': {
          marginLeft: '16px !important',
          '&__price, &__total': {
            paddingLeft: 8,
            paddingRight: 8,
            display: 'inline-block',
            backgroundColor: secondaryMain,
            color: grey,
          },
          '&__variant, &__time': {
            color: `${grey} !important`,
          },
        },
        '.sizes__chip': {
          top: '-10px !important',
          right: '-20px !important',
          color: `${grey} !important`,
          border: 0,
        },
        '.pack': {
          '&__start': {
            color: grey,
          },
          '&__price': {
            paddingLeft: 8,
            paddingRight: 8,
            backgroundColor: secondaryMain,
            color: grey,
          },
          '&__title': {
            '@media (max-width: 768px)': {
              display: 'block !important',
            },
          },
        },
        '.product': {
          '&__price': {
            paddingLeft: 8,
            paddingRight: 8,
            backgroundColor: secondaryMain,
            color: grey,
          },
          '&__area': {
            '@media (min-width: 1024px)': {
              maxWidth: '1024px !important',
            },
          },
          '&__head': {
            flexWrap: 'wrap !important',
          },
        },
        '.basket-product, .cart': {
          '&__total': {
            color: `${grey} !important`,
            display: 'inline-block',
            paddingLeft: 8,
            paddingRight: 8,
            backgroundColor: secondaryMain,
          },
        },
      },
    },
    MuiButton: {
      root: {
        padding: '12px 45px',
        borderRadius: '2px!important',
        textTransform: 'uppercase',
        opacity: '99.99%',
      },
      label: {
        position: 'relative',
        zIndex: 2,
      },
      sizeSmall: {
        padding: '4px 16px',
        lineHeight: 1.6,
      },
      sizeLarge: {
        padding: '12px 45px',
      },
      outlined: {
        padding: '12px 45px',
      },
      outlinedSizeLarge: {
        padding: '12px 45px',
      },
      outlinedSizeSmall: {
        padding: '4px 16px',
        lineHeight: 1.6,
      },
      contained: {
        overflow: 'hidden',
        boxShadow: 'none',
        borderRadius: '2px!important',
        '&:not([disabled]):after': {
          zIndex: 1,
          content: '""',
          position: 'absolute',
          height: '150%',
          width: '150%',
          transition: 'transform .18s ease',
          transform: 'rotate(-10deg)',
          transformOrigin: 'calc(83.33333% - 2.85714em) 0',
          top: '100%',
          left: '-25%',
          backgroundColor: '#d0d0d0',
        },
        '&:hover, &:focus, &:active, &.selected': {
          boxShadow: 'none',
          transition: 'background-color .18s ease .09s',
          color: grey,
          '&:after': {
            transform: 'rotate(0deg) translateY(-100%)',
            transition: 'transform .36s ease',
          },
        },
      },
      containedPrimary: {
        '&:not([disabled])::after': {
          backgroundColor: primaryDark,
        },
        '&:hover, &:focus': {
          color: white,
          backgroundColor: primaryDark,
        },
      },
      containedSecondary: {
        color: `${grey} !important`,
        '&:not([disabled])::after': {
          backgroundColor: secondaryDark,
        },
        '&:hover, &:focus': {
          color: `${grey} !important`,
          backgroundColor: secondaryDark,
        },
      },
      outlinedPrimary: {
        // backgroundColor: '#fff !important',
      },
    },
    MuiPaper: {
      root: {
        boxShadow: '0px 0px 15px rgba(46, 46, 46, 0.1)!important',
      },
      rounded: {
        borderRadius: 10,
      },
    },
    MuiSelect: {
      root: {
        color: '#2933E7',
      },
      selectMenu: {
        '&:focus': {
          background: white,
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        color: `${grey}!important`,
        backgroundColor: `${secondaryMain}!important`,
      },
    },
    MuiChip: {
      root: {
        borderRadius: 0,
        fontSize: 15,
        fontWeight: 700,
        color: '#000',
        backgroundColor: 'transparent',
        height: 25,
        padding: 0,
      },
      label: {
        padding: 0,
      },
      sizeSmall: {
        height: 22,
        fontSize: 12,
        fontWeight: 400,
      },
      labelSmall: {
        paddingLeft: 10,
        paddingRight: 10,
      },
      colorPrimary: {
        color: '#2933E7',
        backgroundColor: 'transparent',
      },
      colorSecondary: {
        color: '#E54C80',
        backgroundColor: 'transparent',
      },
    },
  },
})

export default theme
